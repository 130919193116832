import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { processHTMLContent } from '../../utils/processHTMLContent';
import Seo from '../../components/Seo';
import DisclosuresESI from '../../components/DisclosuresESI/DisclosuresESI';
import { HT_DEFAULT_PAGE_CATEGORY, HT_DEFAULT_PAGE_NAME } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface MembershipTemplateProps {
  page: Queries.WpPage;
}

const MembershipTemplate = ({ data, location }: PageProps<MembershipTemplateProps>) => {
  const {
    page: { content = '', cardMentionsAll, nonAffiliateAll, databaseId, title },
  } = data;

  return (
    <HTPageWrapper
      category={HT_DEFAULT_PAGE_CATEGORY + ', Membership'}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      {processHTMLContent(content || '')}
      <DisclosuresESI
        parentId={databaseId}
        cardMentions={cardMentionsAll}
        nonAffiliate={nonAffiliateAll}
      />
    </HTPageWrapper>
  );
};

export default MembershipTemplate;

export const Head = ({ data }: PageProps<MembershipTemplateProps>) => {
  const { seo } = data.page;
  return <Seo wpSeo={seo} />;
};

export const pageQuery = graphql`
  query MembershipTemplate($id: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageData
    }
  }
`;
